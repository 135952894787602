/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

let nextRoute = ``

exports.onPreRouteUpdate = ({ location }) => {
  nextRoute = location.pathname
}

window.addEventListener("unhandledrejection", event => {
  if (/loading chunk \d* failed./i.test(event.reason)) {
    if (nextRoute) {
      window.location.pathname = nextRoute
    }
  }
})